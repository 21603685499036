import React, { useState } from 'react';
import cardsData from '../db/cards.json';
import { useCodeMaxStore } from '../state/store';
import placeholder from '../assets/img/card-placeholder.png';
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';

type CardType = {
  id: number;
  name: string;
  src: string;
  description: string;
};

export const Cards = () => {
  const cards: number[] = useCodeMaxStore((state) => state.cards);
  const [selectedCard, setSelectedCard] = useState<CardType | null>(null);

  const cardsList = cards.map((val, index) => {
    if (val >= 0) {
      return (
        <div key={index} className="cards">
          <img
            src={cardsData[val].src}
            alt=""
            className="card card-obtained"
            onClick={() => setSelectedCard(cardsData[val])}
          />
        </div>
      );
    } else {
      return (
        <div key={index} className="cards">
          <img src={placeholder} alt="" className="card" />
        </div>
      );
    }
  });

  return (
    <div className="obtainedCards">
      <div className="cardsTitle">CARDS</div>
      <div className="cardsGrid">{cardsList}</div>
      {selectedCard && (
        <Popup open={true} onClose={() => setSelectedCard(null)}>
          <div className="popup-main">
            <img className="mb-15" src={selectedCard.src} alt={selectedCard.name} />
            <h3>{selectedCard.name}</h3>
            <p>{selectedCard.description}</p>
          </div>
        </Popup>
      )}
    </div>
  );
};
