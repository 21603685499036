// missionsSlice.ts
import axios from 'axios';
import { StateCreator } from 'zustand';

export interface MissionsState {
  missions: boolean[] | null;
  lastAccomplishedDate: Date | null;

  fetchMissions: () => Promise<void>;
  missionAccomplish: (index: number) => Promise<void>;
  resetAllMissions: () => Promise<void>;
}

export const createMissionsSlice: StateCreator<MissionsState> = (set, get) => ({
  missions: null,
  lastAccomplishedDate: null,

  /**
   * Завантажити дані про місії з бекенду.
   */
  fetchMissions: async () => {
    try {
      const response = await axios.get('http://code-max.tech:5000/api/hunters', {
        params: { user: 'MASTER' },
      });
      const [doc] = response.data;
      if (doc) {
        set({
          missions: doc.missions,
          lastAccomplishedDate: new Date(doc.lastAccomplishedDate),
        });
      }
    } catch (err) {
      console.error('Помилка fetchMissions:', err);
    }
  },

  /**
   * Виконати місію (index) => приховати місію, оновити lastAccomplishedDate
   */
  missionAccomplish: async (index: number) => {
    const { missions } = get();
    if(!missions) return;

    if (index < 0 || index >= missions.length) return;

    const updated = [...missions];
    updated[index] = false;
    const now = new Date();

    // Оновлюємо локально
    set({
      missions: updated,
      lastAccomplishedDate: now,
    });

    // Синхронізуємо з бекендом
    try {
      await axios.post('http://code-max.tech:5000/api/hunters', {
        user: 'MASTER',
        missions: updated,
        lastAccomplishedDate: now,
      });
    } catch (err) {
      console.error('Помилка missionAccomplish:', err);
    }
  },

  /**
   * Скинути всі місії (true)
   */
  resetAllMissions: async () => {
    const reset = [true, true, true, true, true, true, true];
    set({
      missions: reset,
    });

    try {
      await axios.post('http://code-max.tech:5000/api/hunters', {
        user: 'MASTER',
        missions: reset,
      });
    } catch (err) {
      console.error('Помилка resetAllMissions:', err);
    }
  },
});
