// pointsSlice.ts
import axios from 'axios';
import { StateCreator } from 'zustand';

export interface PointsState {
  user: string;
  title: string;
  points: number;
  availablePoints: number;

  fetchPoints: () => Promise<void>;
  addPoints: (amount: number) => Promise<void>;
  minusPoints: (amount: number) => Promise<void>;
  mintPoints: (leftover: number) => Promise<void>;
}

// Приклад: зберігаємо user="MASTER" чи інший, якщо одного користувача вистачає
export const createPointsSlice: StateCreator<PointsState> = (set, get) => ({
  user: 'MASTER',
  title: 'Hunter',
  points: 0,
  availablePoints: 0,

  /**
   * Отримати з сервера актуальні дані й оновити стан (лише поля points/title/availablePoints).
   * Передбачається, що бекенд повертає об’єкт з усіма полями:
   * {
   *    user, title, points, availablePoints, cards, missions, lastAccomplishedDate
   * }
   */
  fetchPoints: async () => {
    try {
      // Наприклад, GET http://code-max.tech:5000/api/hunters?user=MASTER
      const response = await axios.get('http://code-max.tech:5000/api/hunters', {
        params: { user: get().user },
      });
      const [doc] = response.data; // Припустимо, що повертається масив документів
      if (doc) {
        set({
          user: doc.user,
          title: doc.title,
          points: doc.points,
          availablePoints: doc.availablePoints,
        });
      }
    } catch (err) {
      console.error('Помилка fetchPoints:', err);
    }
  },

  /**
   * Додаємо певну кількість points і відправляємо оновлені дані на бекенд.
   */
  addPoints: async (amount: number) => {
    const { user, points, availablePoints } = get();
    const newPoints = points + amount;
    const newAvailable = availablePoints + amount;

    // Оновлюємо локальний стан
    set({
      points: newPoints,
      availablePoints: newAvailable,
    });

    try {
      // Часткове оновлення
      await axios.post('http://code-max.tech:5000/api/hunters', {
        user,
        title: get().title,
        points: newPoints,
        availablePoints: newAvailable,
      });
    } catch (err) {
      console.error('Помилка addPoints:', err);
    }
  },

  /**
   * Віднімаємо певну кількість points.
   */
  minusPoints: async (amount: number) => {
    const { user, points, availablePoints } = get();
    const newPoints = points - amount;
    const newAvailable = availablePoints - amount;

    set({
      points: newPoints,
      availablePoints: newAvailable,
    });

    try {
      await axios.post('http://code-max.tech:5000/api/hunters', {
        user,
        title: get().title,
        points: newPoints,
        availablePoints: newAvailable,
      });
    } catch (err) {
      console.error('Помилка minusPoints:', err);
    }
  },

  /**
   * Встановити availablePoints у конкретне значення.
   * Наприклад, mintPoints(availablePoints - cardGeneratePrice);
   */
  mintPoints: async (leftover: number) => {
    const { user, points } = get();
    // Оновлюємо локальний стан
    set({ availablePoints: leftover });

    try {
      // Часткове оновлення
      await axios.post('http://code-max.tech:5000/api/hunters', {
        user,
        title: get().title,
        points: points,
        availablePoints: leftover,
      });
    } catch (err) {
      console.error('Помилка mintPoints:', err);
    }
  },
});
