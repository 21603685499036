import React, {useEffect} from 'react';
import {HunterInfo} from './HunterInfo';
import {ControlPanel} from './ControlPanel';
import {Skills} from './Skills';
import {ChatBlock} from "./ChatBlock/ChatBlock";

export const ReputationBlock = () => {

  return (
    <div className="reputation">
      <div className="reputationInfo">
        <div className="reputation-data">
          <HunterInfo/>
          <ControlPanel/>
        </div>
        <ChatBlock />
        <Skills/>
      </div>
    </div>
  );
};
