/* ------------Hunter-App-------------- */
//const oldPoints = 7300;

interface StatusLevel {
  points: number;
  title: string;
  color: string;
}

export const cardGeneratePrice = 10000;
export const maxCardsNum = 75;

export const toastConfig = {
  position: "bottom-right" as const,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark" as const,
}

export const statusLevels: StatusLevel[] = [
  { points: 5000, title: "Mortal Initiate", color: "#C0C0C0" },
  { points: 10000, title: "Divine Aspirant", color: "#FFFFFF" },
  { points: 20000, title: "Celestial Seeker", color: "#1eff00" },
  { points: 30000, title: "Ethereal Challenger", color: "#0070dd" },
  { points: 40000, title: "Mystic Conqueror", color: "#a335ee" },
  { points: 50000, title: "Sacred Bender", color: "#ff8000" },
  { points: 60000, title: "Divinity's Vessel", color: "#e6cc80" },
  { points: 70000, title: "Celestial Harbinger", color: "#ff0000" },
  { points: 80000, title: "Predestined Deity", color: "#00ccff" },
  { points: 90000, title: "Divine Progenitor", color: "#c41f3b" },
  { points: 100000, title: "Demigod", color: "#730695" }
];
