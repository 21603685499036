// useCodeMaxStore.ts
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createPointsSlice, PointsState } from './pointsSlice';
import { createCardsSlice, CardsState } from './cardsSlice';
import { createMissionsSlice, MissionsState } from './missionsSlice';

// Загальний тип стану
type CodeMaxStore = PointsState & CardsState & MissionsState;

export const useCodeMaxStore = create<CodeMaxStore>()(
  devtools((...a) => ({
    ...createPointsSlice(...a),
    ...createCardsSlice(...a),
    ...createMissionsSlice(...a),
  }))
);
