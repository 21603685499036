// cardsSlice.ts
import axios from 'axios';
import { StateCreator } from 'zustand';

export interface CardsState {
  cards: number[];
  fetchCards: () => Promise<void>;
  addCard: (cardValue: number) => Promise<void>;
}

export const createCardsSlice: StateCreator<CardsState> = (set, get) => ({
  cards: [-1, -1, -1, -1, -1, -1, -1, -1, -1],

  /**
   * Завантажити `cards` з бекенду (з того ж документа).
   */
  fetchCards: async () => {
    try {
      const response = await axios.get('http://code-max.tech:5000/api/hunters', {
        // Якщо треба фільтр за user:
        params: { user: 'MASTER' },
      });
      const [doc] = response.data;
      if (doc) {
        set({ cards: doc.cards });
      }
    } catch (err) {
      console.error('Помилка fetchCards:', err);
    }
  },

  /**
   * Додаємо картку (cardValue) у перше вільне (-1) місце,
   * локально оновлюємо стан і зберігаємо у базі.
   */
  addCard: async (cardValue: number) => {
    // Локальне оновлення
    const prevCards = get().cards;
    const newCards = [...prevCards];
    const emptyIndex = newCards.findIndex((c) => c === -1);
    if (emptyIndex !== -1) {
      newCards[emptyIndex] = cardValue;
      set({ cards: newCards });
    }

    // Відправляємо оновлення на бекенд (тільки cards)
    try {
      await axios.post('http://code-max.tech:5000/api/hunters', {
        user: 'MASTER',
        cards: newCards,
      });
    } catch (err) {
      console.error('Помилка addCard:', err);
    }
  },
});
