import React, {useCallback, useEffect, useState} from 'react';
import { useCodeMaxStore } from '../state/store';
import {getRandomNumber} from '../utils/common';
import {cardGeneratePrice, maxCardsNum} from '../constants';
import {MissionBlock} from "./MissionBlock";
import failed from "../db/failed.json";

export const ControlPanel = () => {
  const [points, setPoints] = useState('');

  const fetchCards = useCodeMaxStore((state) => state.fetchCards);
  const fetchPoints = useCodeMaxStore((state) => state.fetchPoints);
  const fetchMissions = useCodeMaxStore((state) => state.fetchMissions);

  const addPoints = useCodeMaxStore((state) => state.addPoints);
  const availablePoints = useCodeMaxStore((state) => state.availablePoints);
  const addCard = useCodeMaxStore((state) => state.addCard);
  const mintPoints = useCodeMaxStore((state) => state.mintPoints);
  const minusPoints = useCodeMaxStore((state) => state.minusPoints);


  useEffect(() => {
    fetchCards();
    fetchPoints();
    fetchMissions();
  }, [fetchCards, fetchMissions, fetchPoints]);

  const onAddRepClick = useCallback(() => {
    const integerPoints = Number(points);
    if (points && integerPoints > 0) {
      addPoints(integerPoints);
      setPoints('0');
    }
  }, [addPoints, points]);

  const onRemoveRepClick = useCallback(() => {
    const integerPoints = Number(points);
    if (points && integerPoints > 0) {
      minusPoints(integerPoints);
      setPoints('0');
    }
  }, [minusPoints, points]);

  const abilityHandler = () => {
    if (availablePoints >= cardGeneratePrice) {
      addCard(getRandomNumber(maxCardsNum));
      mintPoints(availablePoints - cardGeneratePrice);
    }
  };

  const abilityBtn =
    availablePoints >= cardGeneratePrice ? (
      <div className="card-generator">
        <button onClick={abilityHandler} className="addAbility">
          Create ability
        </button>
      </div>
    ) : null;

  return (
    <div className="hunter-panel">
      <hr />
      <div className="availablePoints">
        <span>Power Points:&ensp;<span className="orange">{availablePoints}</span></span>
      </div>
      <div className="points-adder">
        <input
          placeholder={points}
          value={points}
          onChange={(e) => setPoints(e.target.value)}
          className="pointsInput"
          type="number"
        />
        <div className="reputationButtons">
          <button className="pointsBtn addPointBtn" onClick={onAddRepClick}>
            Deposit
          </button>
          <button className="pointsBtn burnPointBtn" onClick={onRemoveRepClick}>
            Burn
          </button>
        </div>
      </div>
      {abilityBtn}
      <div className="failed-mission">
        <MissionBlock title="Failed" missionsData={failed} />
      </div>
    </div>
  );
};
